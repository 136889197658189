import namespace from '@/helpers/namespace';

export default namespace('whiteLabel', {
  getters: [
    'loader',
    'disabledOnLog',
    'avalibilityHour',
    'send705',
    'paymentType',
    'isFree',
    'banks',
    'showMenu',
    'branch',
    'section',
    'showModalLogin',
    'showModalReservationConfirm',
    'showModalUpdateUser',
    'showModalPolitica',
    'cancelUpdateTime',
    'loadHour',
    'showCancelar',
    'avalabilityGroup',
    'isAvalability',
    'invite',
    'locked',
    'selectedExperience',
    'integrations',
    'integrationDefaults',
    'vendorId',
    'idVendor',
    'vendor',
    'loginProcessReservation',
    'popupAlert3Cordilleras',
    'popupWaitingList',
    'popupSuccessWaitingList',
    'getStateRequiredException',
    'stateModalExperience',
    'hourGlobal',
    'topBarSize',
    'tooltipTYC'
  ],
  actions: [
    'avalibilityHour',
    'avalibilityHourNoCommit',
    'send705',
    'banks',
    'branch',
    'vendorsAssociates',
    'getAvalibilityGroup',
    'getReservationTypes',
    'vendorId',
    'getIntegrations',
    'getIntegrationDefaults',
    'vendor',
    'locked'
  ],
  mutations: [
    'setLoader',
    'setDisabledOnLog',
    'setAvalibilityHour',
    'setSend705',
    'setPaymentType',
    'setIsFree',
    'setBanks',
    'setShowMenu',
    'setBranch',
    'setSection',
    'setShowModalLogin',
    'setShowModalReservationConfirm',
    'setShowModalUpdateUser',
    'setShowModalPolitica',
    'setCancelUpdateTime',
    'setLoadHour',
    'setShowCancelar',
    'setAvalabilityGroup',
    'setIsAvalability',
    'setIntegrations',
    'setIntegrationDefaults',
    'setVendor',
    'setVendorId',
    'setId',
    'setInvite',
    'setLocked',
    'setSelectedExperience',
    'setLoginProcessReservation',
    'setPopupAlert3Cordilleras',
    'setPopupWaitingList',
    'setPopupSuccessWaitingList',
    'setStateRequiredException',
    'setModalExperience',
    'setHourGlobal',
    'setTopBarSize',
    'setTooltipTYC'
  ]
});
