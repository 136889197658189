import Bowser from 'bowser';
import moment from 'moment-timezone';

function analyticClient (Axios) {
  try {
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const obj = {
      vendorId: localStorage.getItem('_id') ? localStorage.getItem('_id') : null,
      ip: '',
      path: window.location.pathname,
      city: '',
      country: window.location.hostname,
      userId: localStorage.getItem('_userId') ? localStorage.getItem('_userId') : null,
      browser: bowser.getBrowserName(),
      device: bowser.getPlatformType(),
      system: bowser.getOSName(),
      systemVersion: bowser.getOSVersion() === undefined ? null : bowser.getOSVersion(),
      visitDate: moment().format('YYYY-MM-DD hh:mm'),
      source: 'select',
      date: ''
    };
    const info = Axios.post('/analytics/navigator', obj).then(() => {
      return 'Ok';
    });
    return info;
  } catch (e) {
    console.error('Error', e);
  }
}

export default analyticClient;
