import userTypes from '@/store/types/user';
import serviceUser from '@/helpers/apis/serviceUser';

const state = {};

const actions = {
  [userTypes.actions.getUnsubscribe]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('restaurants/accept/unsubscribe', data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const getters = {};

const mutations = {};

export default {
  state,
  actions,
  getters,
  mutations
};
