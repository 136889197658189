import Vue from 'vue';
import eventTypes from '@/store/types/event';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import modalNA from '@/components/Modals/modalNoAvalability';
import { ModalProgrammatic as ModalJ } from 'buefy';

const state = {
  allEvents: [],
  event: null,
  eventTextWithPayment: null,
  eventFull: null,
  showDescriptionEvent: false,
  popupAlertEvent: false,
  reservationEvent: false,
  eventPrice: 0,
  eventMinPeople: null,
  dateTimeEventGlobal: [],
  eventIdStaticGlobal: null,
  isEventDayGlobal: false,
  currentMonthGlobal: null
};

const actions = {
  [eventTypes.actions.getTimesEvent]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('vendors/findEvent', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => { reject(error); });
    });
  },
  [eventTypes.actions.allEvents]: ({ commit }, info) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('vendors/allEvents', info.data)
        .then(({ data }) => {
          commit(eventTypes.mutations.setAllEvents, data.data);
          resolve(data.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [eventTypes.actions.event]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('vendors/event/' + data.data.id)
        .then(response => {
          commit(eventTypes.mutations.setEvent, response.data);
          resolve(response.data);
        }).catch(error => { reject(error); });
    });
  },
  [eventTypes.actions.preReservationEvent]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('reservation/preReservationEvent', data.data)
        .then(response => {
          if (response.data.code === 200) {
            data.data.futureReservationsId = response.data.futureReservations;
            data.data.vendorAvailability = response.data.vendorAvailability;
            window.localStorage.setItem('_reservation', btoa(JSON.stringify(data.data)));
            commit(reservationTypes.mutations.setReservationsUser);
          }
          if (parseInt(response.data.code) === 410) {
            ModalJ.open({
              component: modalNA,
              hasModalCard: true,
              canCancel: false,
              scroll: 'keep'
            });
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          } else {
            resolve(response.data);
          }
        }).catch(error => {
          reject(error);
        });
    });
  }
};
const getters = {
  [eventTypes.getters.allEvents]: state => state.allEvents,
  [eventTypes.getters.event]: state => state.event,
  [eventTypes.getters.eventPrice]: state => state.eventPrice,
  [eventTypes.getters.eventTextWithPayment]: state => state.eventTextWithPayment,
  [eventTypes.getters.eventFull]: state => state.eventFull,
  [eventTypes.getters.showDescriptionEvent]: state => state.showDescriptionEvent,
  [eventTypes.getters.popupAlertEvent]: state => state.popupAlertEvent,
  [eventTypes.getters.reservationEvent]: state => state.reservationEvent,
  [eventTypes.getters.eventMinPeople]: state => state.eventMinPeople,
  [eventTypes.getters.dateTimeEventGlobal]: state => state.dateTimeEventGlobal,
  [eventTypes.getters.eventIdStaticGlobal]: state => state.eventIdStaticGlobal,
  [eventTypes.getters.isEventDayGlobal]: state => state.isEventDayGlobal,
  [eventTypes.getters.currentMonthGlobal]: state => state.currentMonthGlobal
};

const mutations = {
  [eventTypes.mutations.setAllEvents] (state, data) {
    state.allEvents = data;
  },
  [eventTypes.mutations.setEvent] (state, data) {
    state.event = data;
  },
  [eventTypes.mutations.setEventPrice] (state, data) {
    state.eventPrice = data;
  },
  [eventTypes.mutations.setEventTextWithPayment] (state, data) {
    state.eventTextWithPayment = data;
  },
  [eventTypes.mutations.setEventFull] (state, data) {
    state.eventFull = data;
  },
  [eventTypes.mutations.setShowDescriptionEvent] (state, data) {
    state.showDescriptionEvent = data;
  },
  [eventTypes.mutations.setPopupAlertEvent] (state, data) {
    state.popupAlertEvent = data;
  },
  [eventTypes.mutations.setReservationEvent] (state, data) {
    state.reservationEvent = data;
  },
  [eventTypes.mutations.setEventMinPeople] (state, data) {
    state.eventMinPeople = data;
  },
  [eventTypes.mutations.setDateTimeEventGlobal] (state, data) {
    state.dateTimeEventGlobal = data;
  },
  [eventTypes.mutations.setEventIdStaticGlobal] (state, data) {
    state.eventIdStaticGlobal = data;
  },
  [eventTypes.mutations.setIsEventDayGlobal] (state, data) {
    state.isEventDayGlobal = data;
  },
  [eventTypes.mutations.setCurrentMonthGlobal] (state, data) {
    state.currentMonthGlobal = data;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
