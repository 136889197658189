import namespace from '@/helpers/namespace';

export default namespace('auth', {
  getters: [
    'user',
    'userId',
    'logged',
    'inviteId'
  ],
  actions: [
    'login',
    'register',
    'reset',
    'logout',
    'checkEmail',
    'updateUser',
    'validateToken',
    'changedPassword',
    'refreshUser',
    'loginInvite'
  ],
  mutations: [
    'setUser',
    'setLogged',
    'setUserId'
  ]
});
