export default {
  custom: {
    email: {
      required: 'El correo electrónico es requerido',
      email: 'El correo electrónico no es válido'
    },
    mail: {
      required: 'El correo electrónico es requerido',
      email: 'El correo electrónico no es válido'
    },
    emails: {
      required: 'El correo electrónico es requerido',
      email: 'El correo electrónico no es válido'
    },
    username: {
      required: 'El nombre de usuario es requerido',
      alpha_spaces: 'Valor no permitido, solo se permiten letras'
    },
    identification: {
      required: 'El valor del campo es requerido',
      numeric: 'Solo se permiten números',
      min: 'Ingrese mínimo 5 números',
      max: 'Ingrese máximo 20 caracteres'
    },
    address_checkout: {
      required: 'Se requiere la dirección',
      numeric: 'Ingrese solo números'
    },
    name: {
      required: 'El nombre es requerido',
      alpha_spaces: 'Solo se permiten letras'
    },
    displayName: {
      required: 'El nombre es obligatorio',
      min: 'El nombre debe tener minimo 3 caracteres',
      regex: 'No se permiten caracteres especiales'
    },
    password: {
      required: 'La contraseña es requerida',
      min: 'La contraseña debe tener minimo 6 caracteres'
    },
    password_confirmation: {
      required: 'Se requiere que confirme la contraseña',
      min: 'La contraseña es demasiado corta',
      confirmed: 'La contraseña no coincide'
    },
    phone: {
      required: 'El telefono es requerido',
      numeric: 'Ingrese solo números',
      min: 'Debe ingresar un mínimo de caracteres',
      max: 'Ha superado el límite de caracteres'
    },
    indicative: {
      required: 'El indicativo es requerido',
      numeric: 'Ingrese solo números seguidos de una +',
      min: 'Debe ingresar un mínimo de caracteres',
      max: 'Ha superado el límite de caracteres'
    },
    number: {
      required: 'El telefono es requerido',
      numeric: 'Ingrese solo números',
      min: 'Debe ingresar un mínimo de caracteres',
      max: 'Ha superado el límite de caracteres'
    },
    phones: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    address: {
      required: 'Se requiere la dirección de habitación',
      numeric: 'Ingrese solo números'
    },
    mes: {
      required: 'Campo requerido',
      numeric: 'Ingrese solo números'
    },
    mesCard: {
      required: 'Campo requerido',
      numeric: 'Ingrese solo números'
    },
    ano: {
      required: 'Campo requerido',
      numeric: 'Ingrese solo números'
    },
    city: {
      required: 'Se requiere que ingrese la ciudad',
      min: 'Minimo de caracteres no permitido'
    },
    creditCard: {
      required: 'Se requiere que ingrese el número de la tarjeta de crédito',
      error: 'Número de tarjeta de crédito no valida'
    },
    bank: {
      required: 'Por favor, seleccione una entidad bancaria'
    },
    typeDoc: {
      required: 'Por favor, seleccione un tipo de documento'
    },
    country: {
      required: 'El valor del campo es requerido'
    },
    identityDocument: {
      required: 'El valor del campo es requerido'
    },
    typePeople: {
      required: 'Por favor, seleccione un tipo de persona'
    },
    people: {
      min_value: 'El valor minimo permitido es '
    },
    namePerson: {
      required: 'El nombre es obligatorio'
    },
    nameBuyer: {
      required: 'El nombre es obligatorio'
    },
    emailPerson: {
      required: 'El correo electrónico es obligatorio',
      email: 'El correo electrónico no es válido'
    },
    emailBuyer: {
      required: 'El correo electrónico es obligatorio',
      email: 'El correo electrónico no es válido'
    },
    valueGift: {
      required: 'El valor es obligatorio'
    },
    messageCard: {
      required: 'El mensaje es obligatorio'
    },
    dateGift: {
      required: 'La fecha es obligatoria'
    },
    cuota: {
      required: 'Campo requerido'
    },
    cvc: {
      required: 'Campo requerido'
    },
    cedula: {
      required: 'La cedula es requerida'
    },
    cardNumber: {
      required: 'Número de tarjeta Requerido',
      'credit_card': 'Número de tarjeta invalido'
    },
    cardholderName: {
      required: 'El nombre es requerido',
      min: 'Debe ingresar un mínimo de caracteres',
      alpha_spaces: 'Solo se permiten letras'
    },
    cardExpirationMonth: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    cardExpirationYear: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    securityCode: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números',
      min: 'Debe tener minimo 3 caracteres'
    },
    docType: {
      required: 'Por favor, seleccione un tipo de documento'
    },
    docNumber: {
      required: 'El valor del campo es requerido',
      numeric: 'Solo se permiten números'
    },
    name_companion: {
      required: 'Ingrese un nombre',
      min: 'Ingrese un nombre mayor a 2 letras'
    },
    identification_companion: {
      required: 'Ingrese número de identificación',
      numeric: 'Solo se permiten números',
      min: 'El valor mínimo permitido es 5 números'
    },
    temperature_companion: {
      required: 'Ingrese la temperatura',
      numeric: 'Ingrese solo números',
      min_value: 'El valor mínimo es 0'
    },
    phone_companion: {
      required: 'Ingrese número de télefono',
      max: 'El valor máximo permitido es 14 números',
      min: 'El valor mínimo permitido es 10 números',
      numeric: 'Ingrese solo números'
    },
    email_companion: {
      required: 'Ingrese un correo electrónico',
      email: 'El correo electrónico no es válido'
    },
    pais_companion: {
      required: 'Debe seleccionar un pais'
    },
    huesped: {
      required: 'Debe especificar el huesped',
      min: 'Debe especificar el huesped'
    },
    clientType: {
      required: 'Debe especificar el tipo de cliente'
    },
    mediaType: {
      required: 'Debe seleccionar una opción'
    },
    legalPersonName: {
      required: 'El nombre de la empresa es requerido',
      min: 'Ingrese un nombre mayor a 2 letras'
    },
    card: {
      required: 'El número de tarjeta es requerido',
      min: 'Número de tarjeta no válido'
    }
  },
  messages: {
    min: (_, length) => `El campo debe tener al menos ${length} caracteres`,
    required: 'El campo es requerido'
  }
};
