import sectionTypes from '@/store/types/section';
import serviceSection from '@/helpers/apis/serviceVendor';

const state = {
  zones: false
};

const actions = {
  [sectionTypes.actions.getZones]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceSection.post('section/select', data)
        .then(({ data }) => {
          commit(sectionTypes.mutations.setZones, data.data);
          resolve(data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const getters = {
  [sectionTypes.getters.zones]: state => state.zones
};

const mutations = {
  [sectionTypes.mutations.setZones] (state, data) {
    state.zones = data;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
