import namespace from '@/helpers/namespace';

export default namespace('global', {
  actions: [
    'reservation',
    'getServerTime'
  ],
  getters: [
    'online',
    'url',
    'vendorUrl',
    'socket',
    'isMobile',
    'orientation',
    'width',
    'height',
    'reservation',
    'serverTime',
    'versionApp',
    'verifyingPayment',
    'languageApp',
    'embedSystem'
  ],
  mutations: [
    'setVendorUrl',
    'setOnline',
    'setReservation',
    'setSocket',
    'setWidth',
    'setHeight',
    'setUrl',
    'setServerTime',
    'setVersionApp',
    'setVerifyingPayment',
    'setLanguageApp',
    'setEmbedSystem'
  ]
});
