import namespace from '@/helpers/namespace';

export default namespace('event', {
  getters: [
    'allEvents',
    'event',
    'eventPrice',
    'eventTextWithPayment',
    'eventFull',
    'showDescriptionEvent',
    'popupAlertEvent',
    'reservationEvent',
    'eventMinPeople',
    'dateTimeEventGlobal',
    'eventIdStaticGlobal',
    'isEventDayGlobal',
    'currentMonthGlobal'
  ],
  actions: [
    'getTimesEvent',
    'allEvents',
    'event',
    'preReservationEvent'
  ],
  mutations: [
    'setAllEvents',
    'setEvent',
    'setEventPrice',
    'setEventTextWithPayment',
    'setEventFull',
    'setShowDescriptionEvent',
    'setPopupAlertEvent',
    'setReservationEvent',
    'setEventMinPeople',
    'setDateTimeEventGlobal',
    'setEventIdStaticGlobal',
    'setIsEventDayGlobal',
    'setCurrentMonthGlobal'
  ]
});
