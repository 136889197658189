import Vue from 'vue';
import authTypes from '@/store/types/auth';
import serviceAuth from '@/helpers/apis/serviceAuth';

const state = {
  user: [],
  logged: null,
  userId: null,
  inviteId: 13744
};

const actions = {
  [authTypes.actions.login]: ({ commit }, userInput) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('/login', userInput)
        .then(user => {
          if (parseInt(user.status) === 200) {
            window.localStorage.setItem('_token', user.data.token);
            window.localStorage.setItem('_user', btoa(JSON.stringify(user.data.user)));
            window.localStorage.setItem('_userId', user.data.user.id);
            commit(authTypes.mutations.setUser);
            commit(authTypes.mutations.setUserId);
          }
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.updateUser]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('/update', data)
        .then(response => {
          window.localStorage.setItem('_user', btoa(JSON.stringify(response.data.user)));
          commit(authTypes.mutations.setUser);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.loginInvite]: ({ commit }, userInput) => {
    return new Promise((resolve, reject) => {
      const userData = {
        displayName: userInput.data.name,
        email: userInput.data.email.trim(),
        phone: userInput.data.phone,
        indicativo: userInput.data.indicativo,
        bandera: userInput.data.bandera,
        country: userInput.data.country,
        id: userInput.userId,
        userId: userInput.userId,
        documentType: userInput.data.documentType && userInput.data.identityDocument ? userInput.data.documentType : null,
        identityDocument: userInput.data.documentType && userInput.data.identityDocument ? userInput.data.identityDocument : null
      };
      delete userInput.data;
      delete userInput.userId;
      serviceAuth.post('/loginInvite', userInput)
        .then(user => {
          if (parseInt(user.status) === 200) {
            window.localStorage.setItem('_token', user.data.token);
            window.localStorage.setItem('_user', btoa(JSON.stringify(userData)));
            window.localStorage.setItem('_userId', user.data.user.id);
            commit(authTypes.mutations.setUser);
            commit(authTypes.mutations.setUserId);
          }
          resolve(user.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.register]: ({ commit }, userInput) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('/register', userInput)
        .then(user => {
          if (parseInt(user.data.code) !== 400 && parseInt(user.data.code) !== 403 && parseInt(user.data.code) !== 404) {
            window.localStorage.setItem('_token', user.data.token);
            window.localStorage.setItem('_user', btoa(JSON.stringify(user.data.user)));
            window.localStorage.setItem('_userId', user.data.user.id);
            commit(authTypes.mutations.setUser);
            commit(authTypes.mutations.setUserId);
          }
          resolve(user.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.reset]: (state, userInput) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('/reset', userInput)
        .then(user => {
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.logout]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('/logout', user)
        .then(() => {
          window.localStorage.removeItem('_token');
          window.localStorage.removeItem('_user');
          window.localStorage.removeItem('_userId');
          commit(authTypes.mutations.setUser);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.checkEmail]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('/checkEmail', data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.changedPassword]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('/updatePassword', data)
        .then(response => {
          if (parseInt(response.status) === 200) {
            window.localStorage.setItem('_user', btoa(JSON.stringify(response.data.user)));
            commit(authTypes.mutations.setUser);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.refreshUser]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/auth/refreshUser', data.data)
        .then(response => {
          if (parseInt(response.status) === 200) {
            if (response.data.isActive) {
              window.localStorage.setItem('_user', btoa(JSON.stringify(response.data.user)));
              commit(authTypes.mutations.setUser);
            } else {
              window.localStorage.removeItem('_token');
              window.localStorage.removeItem('_user');
              window.localStorage.removeItem('_userId');
              commit(authTypes.mutations.setUser);
            }
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.validateToken]: (state, token) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('/validateToken', { token: token })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  }
};

const getters = {
  // obtenemos el usuario
  [authTypes.getters.user]: (state) => {
    return state.user;
  },
  [authTypes.getters.userId]: (state) => {
    return state.userId;
  },
  // está logueado?
  [authTypes.getters.logged]: (state) => {
    return state.logged;
  },
  [authTypes.getters.inviteId]: (state) => {
    return state.inviteId;
  }
};

const mutations = {
  [authTypes.mutations.setShowChangedPassword]: (state, data) => {
    state.changedPassword = data;
  },
  // establecemos el user a través del token jwt
  [authTypes.mutations.setUser]: (state) => {
    if (window.localStorage.getItem('_user')) {
      let json = JSON.parse(atob(window.localStorage.getItem('_user')));
      state.user = json;
      state.logged = true;
    } else {
      state.logged = false;
      state.user = [];
    }
  },
  [authTypes.mutations.setUserId]: (state) => {
    if (window.localStorage.getItem('_userId')) {
      state.userId = window.localStorage.getItem('_userId');
    }
  },
  // establecemos el estado del usuario
  [authTypes.mutations.setLogged]: (state) => {
    if (window.localStorage.getItem('_token')) {
      state.logged = true;
    } else {
      state.logged = false;
      state.user = '';
    }
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
