/* eslint-disable prefer-promise-reject-errors */
import reservationTypes from '@/store/types/reservation';
import whiteLabelTypes from '@/store/types/whiteLabel';
import axios from 'axios';
import Vue from 'vue';

const state = {
  invite: false,
  integrations: [],
  integrationDefaults: [],
  vendorId: (process.browser && window.localStorage.getItem('_vendorId') ? window.localStorage.getItem('_vendorId') : []),
  idVendor: 0,
  vendor: [],
  loader: true,
  disabledOnLog: false,
  avalibilityHour: null,
  send705: false,
  paymentType: false,
  isFree: null,
  banks: null,
  showMenu: null,
  branch: null,
  section: null,
  showModalLogin: false,
  showModalReservationConfirm: false,
  showModalUpdateUser: false,
  showModalPolitica: false,
  cancelUpdateTime: null,
  loadHour: false,
  showCancelar: false,
  avalabilityGroup: false,
  isAvalability: false,
  locked: [],
  selectedExperience: null,
  loginProcessReservation: false,
  popupAlert3Cordilleras: false,
  popupWaitingList: false,
  popupSuccessWaitingList: false,
  stateRequiredExperience: true,
  stateModalExperience: false,
  hourGlobal: null,
  topBarSize: 140,
  tooltipTYC: false
};
const actions = {
  [whiteLabelTypes.actions.branch]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('vendors/branch/' + data.data.url)
        .then(response => {
          commit(whiteLabelTypes.mutations.setBranch, response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [whiteLabelTypes.actions.vendor]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('vendors/' + vendor.data.vendorId)
        .then(response => {
          if (process.browser) {
            commit(whiteLabelTypes.mutations.setVendor, response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [whiteLabelTypes.actions.vendorId]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('vendorFromUrl?url=' + vendor.data.url)
        .then(response => {
          if (process.browser && response.data.code === 200) {
            window.localStorage.setItem('_vendorId', response.data.vendorId);
            window.localStorage.setItem('_id', response.data.Id);
          }
          commit(whiteLabelTypes.mutations.setVendorId);
          commit(whiteLabelTypes.mutations.setId);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [whiteLabelTypes.actions.getIntegrations]: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('admin/integrations/' + data.vendorId)
        .then(response => {
          commit(whiteLabelTypes.mutations.setIntegrations, response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [whiteLabelTypes.actions.getIntegrationDefaults]: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('admin/integrations/null?filter=' + data.filter)
        .then(response => {
          commit(whiteLabelTypes.mutations.setIntegrationDefaults, response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [whiteLabelTypes.actions.avalibilityHour]: ({ commit }, data) => {
    const source = axios.CancelToken.source();
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        source.cancel(); // Cancelar la solicitud
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ type: 'timeOut' });
        // Lógica de tiempo de espera
      }, 15000);
      Vue.axios.get('vendors/availability/time/' + data.data.vendorId + '/' + data.data.timestamp + '/0/' + data.data.people + '/' + data.data.zone + '/' + data.data.typeReservation + '/' + data.data.tableId, {
        cancelToken: source.token
      }).then(response => {
        clearTimeout(timeout);
        if (response) {
          commit(whiteLabelTypes.mutations.setAvalibilityHour, response.data);
          resolve(response.data);
        } else {
          reject({ type: 'timeOut' });
        }
      }).catch(error => {
        if (error.toJSON().message === 'Network Error') {
          reject({ type: 'timeOut' });
        }
        reject(error);
      });
    });
  },
  [whiteLabelTypes.actions.avalibilityHourNoCommit]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('vendors/availability/time/' + data.data.vendorId + '/' + data.data.timestamp + '/0/' + data.data.people + '/' + data.data.zone + '/' + data.data.typeReservation + '/' + data.data.tableId)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        }).finally(() => { });
    });
  },
  [whiteLabelTypes.actions.getReservationTypes]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('vendors/reservationTypes/' + data.data.vendorId)
        .then(response => {
          commit(reservationTypes.mutations.setReservationTypes, response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [whiteLabelTypes.actions.banks]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('vendors/pse/banks/' + data.data.vendorId)
        .then(response => {
          commit(whiteLabelTypes.mutations.setBanks, response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [whiteLabelTypes.actions.getAvalibilityGroup]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('vendors/group/associates/availability', data)
        .then(response => {
          commit(whiteLabelTypes.mutations.setAvalabilityGroup, response.data);
          resolve(response.data);
        })
        .catch(error => { reject(error); });
    });
  }
};
const getters = {
  [whiteLabelTypes.getters.invite]: state => state.invite,
  [whiteLabelTypes.getters.loader]: state => state.loader,
  [whiteLabelTypes.getters.integrations]: state => state.integrations,
  [whiteLabelTypes.getters.integrationDefaults]: state => state.integrationDefaults,
  [whiteLabelTypes.getters.vendor]: state => state.vendor,
  [whiteLabelTypes.getters.vendorId]: state => state.vendorId,
  [whiteLabelTypes.getters.idVendor]: state => state.idVendor,
  [whiteLabelTypes.getters.disabledOnLog]: state => state.disabledOnLog,
  [whiteLabelTypes.getters.avalibilityHour]: state => state.avalibilityHour,
  [whiteLabelTypes.getters.send705]: state => state.send705,
  [whiteLabelTypes.getters.paymentType]: state => state.paymentType,
  [whiteLabelTypes.getters.isFree]: state => state.isFree,
  [whiteLabelTypes.getters.banks]: state => state.banks,
  [whiteLabelTypes.getters.showMenu]: state => state.showMenu,
  [whiteLabelTypes.getters.branch]: state => state.branch,
  [whiteLabelTypes.getters.section]: state => state.section,
  [whiteLabelTypes.getters.showModalLogin]: state => state.showModalLogin,
  [whiteLabelTypes.getters.showModalReservationConfirm]: state => state.showModalReservationConfirm,
  [whiteLabelTypes.getters.showModalUpdateUser]: state => state.showModalUpdateUser,
  [whiteLabelTypes.getters.showModalPolitica]: state => state.showModalPolitica,
  [whiteLabelTypes.getters.cancelUpdateTime]: state => state.cancelUpdateTime,
  [whiteLabelTypes.getters.loadHour]: state => state.loadHour,
  [whiteLabelTypes.getters.showCancelar]: state => state.showCancelar,
  [whiteLabelTypes.getters.avalabilityGroup]: state => state.avalabilityGroup,
  [whiteLabelTypes.getters.isAvalability]: state => state.isAvalability,
  [whiteLabelTypes.getters.locked]: state => state.locked,
  [whiteLabelTypes.getters.selectedExperience]: state => state.selectedExperience,
  [whiteLabelTypes.getters.loginProcessReservation]: state => state.loginProcessReservation,
  [whiteLabelTypes.getters.popupAlert3Cordilleras]: state => state.popupAlert3Cordilleras,
  [whiteLabelTypes.getters.popupWaitingList]: state => state.popupWaitingList,
  [whiteLabelTypes.getters.popupSuccessWaitingList]: state => state.popupSuccessWaitingList,
  [whiteLabelTypes.getters.getStateRequiredException]: state => state.stateRequiredExperience,
  [whiteLabelTypes.getters.stateModalExperience]: state => state.stateModalExperience,
  [whiteLabelTypes.getters.hourGlobal]: state => state.hourGlobal,
  [whiteLabelTypes.getters.topBarSize]: state => state.topBarSize,
  [whiteLabelTypes.getters.tooltipTYC]: state => state.tooltipTYC

};
const mutations = {
  [whiteLabelTypes.mutations.setVendorId] (state) {
    state.vendorId = (process.browser && window.localStorage.getItem('_vendorId')) ? window.localStorage.getItem('_vendorId') : [];
  },
  [whiteLabelTypes.mutations.setId] (state) {
    state.idVendor = (process.browser && window.localStorage.getItem('_id')) ? window.localStorage.getItem('_id') : [];
  },
  [whiteLabelTypes.mutations.setVendor] (state, vendor) {
    state.vendor = vendor;
  },
  [whiteLabelTypes.mutations.setInvite] (state, data) {
    state.invite = data;
  },
  [whiteLabelTypes.mutations.setLoader] (state, loader) {
    state.loader = loader;
  },
  [whiteLabelTypes.mutations.setDisabledOnLog] (state, disabledOnLog) {
    state.disabledOnLog = disabledOnLog;
  },
  [whiteLabelTypes.mutations.setAvalibilityHour] (state, avalibilityHour) {
    state.avalibilityHour = avalibilityHour;
  },
  [whiteLabelTypes.mutations.setSend705] (state, send705) {
    state.send705 = send705;
  },
  [whiteLabelTypes.mutations.setPaymentType] (state, paymentType) {
    state.paymentType = paymentType; // payment method (1 => credit card, 2 => pse)
  },
  [whiteLabelTypes.mutations.setIsFree] (state, isFree) {
    state.isFree = isFree;
  },
  [whiteLabelTypes.mutations.setBanks] (state, banks) {
    state.banks = banks;
  },
  [whiteLabelTypes.mutations.setShowMenu] (state, showMenu) {
    state.showMenu = showMenu;
  },
  [whiteLabelTypes.mutations.setBranch] (state, branch) {
    state.branch = branch;
  },
  [whiteLabelTypes.mutations.setIntegrations] (state, integrations) {
    state.integrations = integrations;
  },
  [whiteLabelTypes.mutations.setIntegrationDefaults] (state, integrationDefaults) {
    state.integrationDefaults = integrationDefaults;
  },
  [whiteLabelTypes.mutations.setSection] (state, section) {
    state.section = section;
  },
  [whiteLabelTypes.mutations.setShowModalLogin] (state, showModalLogin) {
    state.showModalLogin = showModalLogin;
  },
  [whiteLabelTypes.mutations.setShowModalReservationConfirm] (state) {
    state.showModalReservationConfirm = !!((process.browser && window.localStorage.getItem('_reservationOk')));
  },
  [whiteLabelTypes.mutations.setShowModalUpdateUser] (state, showModalUpdateUser) {
    state.showModalUpdateUser = showModalUpdateUser;
  },
  [whiteLabelTypes.mutations.setShowModalPolitica] (state, showModalPolitica) {
    state.showModalPolitica = showModalPolitica;
  },
  [whiteLabelTypes.mutations.setCancelUpdateTime] (state, cancelUpdateTime) {
    state.cancelUpdateTime = cancelUpdateTime;
  },
  [whiteLabelTypes.mutations.setLoadHour] (state, loadHour) {
    state.loadHour = loadHour;
  },
  [whiteLabelTypes.mutations.setShowCancelar] (state, showCancelar) {
    state.showCancelar = showCancelar;
  },
  [whiteLabelTypes.mutations.setAvalabilityGroup] (state, data) {
    state.avalabilityGroup = data;
  },
  [whiteLabelTypes.mutations.setIsAvalability] (state, data) {
    state.isAvalability = data;
  },
  [whiteLabelTypes.mutations.setLocked] (state, data) {
    state.locked = data;
  },
  [whiteLabelTypes.mutations.setSelectedExperience] (state, data) {
    state.selectedExperience = data;
  },
  [whiteLabelTypes.mutations.setLoginProcessReservation] (state, data) {
    state.loginProcessReservation = data;
  },
  [whiteLabelTypes.mutations.setPopupAlert3Cordilleras] (state, data) {
    state.popupAlert3Cordilleras = data;
  },
  [whiteLabelTypes.mutations.setPopupWaitingList] (state, data) {
    state.popupWaitingList = data;
  },
  [whiteLabelTypes.mutations.setPopupSuccessWaitingList] (state, data) {
    state.popupSuccessWaitingList = data;
  },
  [whiteLabelTypes.mutations.setStateRequiredException] (state, data) {
    state.stateRequiredExperience = data;
  },
  [whiteLabelTypes.mutations.setModalExperience] (state, data) {
    state.stateModalExperience = data;
  },
  [whiteLabelTypes.mutations.setHourGlobal] (state, data) {
    state.hourGlobal = data;
  },
  [whiteLabelTypes.mutations.setTopBarSize] (state, data) {
    state.topBarSize = data;
  },
  [whiteLabelTypes.mutations.setTooltipTYC] (state, status) {
    state.tooltipTYC = status;
  }
};
export default {
  state,
  actions,
  getters,
  mutations
};
