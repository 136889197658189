import axios from 'axios';

export default axios.create({
  baseURL: process.env.VUE_APP_URL_VENDOR,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + window.localStorage.getItem('_token')
  }
});
