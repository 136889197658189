export default {
  return: `Volver`,
  selectData: `Selecciona datos`,
  select: `Elige`,
  book: `Confirmar reserva`,
  bookRequest: `Confirmar solicitud de reserva`,
  finishBookin: `Finalizar Reserva`,
  numberDiners: `Número de personas`,
  needEvent: `Si necesitas un Evento`,
  contactEvent: `Comunícate con nosotros`,
  biggerReservation: `reserva mas grande`,
  phone: `Teléfono`,
  email: `Email`,
  date: `Fecha`,
  chooseAvailableDate: `Selecciona la fecha para tu reserva`,
  january: `enero`,
  february: `febrero`,
  march: `marzo`,
  april: `abril`,
  may: `mayo`,
  june: `junio`,
  july: `julio`,
  august: `agosto`,
  september: `septiembre`,
  october: `octubre`,
  november: `noviembre`,
  december: `diciembre`,
  area: `zona`,
  availableArea: `Seleccione una zona`,
  availableArea_Map: `Mapa del restaurante`,
  noAvailableArea: `no hay zonas disponibles`,
  close: `Cerrar`,
  unavailable: `sin disponibilidad`,
  hour: `Hora`,
  monday: `Lunes`,
  tuesday: `Martes`,
  wednesday: `Miércoles`,
  thursday: `Jueves`,
  friday: `Viernes`,
  saturday: `Sábado`,
  sunday: `Domingo’`,
  availableHour: `Horas disponibles`,
  noAvailableHour: `Horas no disponibles`,
  loading: `Cargando`,
  chooseAvailableHour: `escoge una hora`,
  completeFilds: `Llena todos los datos`,
  continue: `Continuar`,
  continueUpper: `CONTINUAR`,
  name: `Nombre`,
  country: `País`,
  mobile: `Celular`,
  idNumber: `Documento de identidad`,
  diners: `Personas`,
  littleCompleteReservation: `Falta poco para completar tu reserva`,
  littleCompleteReservationRequest: `Falta poco para completar tu solicitud de reserva`,
  verifyData: `Verifica los datos y haz click en "Confirmar Reserva"`,
  verifyDataRequest: `Verifica los datos y haz click en "Confirmar Solicitud de Reserva"`,
  clientInformation: `Datos del cliente`,
  bookingInformation: `Datos de la reserva`,
  notification: `Notificación`,
  bookNow: `Reservar`,
  againReservation: `Se cumplió el tiempo establecido para confirmar tu reserva, debes iniciar el proceso de nuevo para realizar tu reserva`,
  agree: `Aceptar`,
  timeOverReservation: `¡Finalizó el tiempo límite para pagar tu reserva, esta será cancelada!`,
  generateNewLink: `En caso que quieras tener un nuevo link de pago comunícate con nosotros`,
  subscribe: `Suscribirse`,
  comments: `Comentarios`,
  comments_alergy: `¿Tienes alguna restricción alimentaria o alergia?`,
  comments_pet: `¿Traes mascota?`,
  typeCelebration: `Si tienes algún tipo de celebración cuéntanos`,
  reservationConfirm: `Confirmar reserva`,
  requestReservationConfirm: `Confirmar solicitud de reserva`,
  timeOutReservation: `El tiempo para realizar tu reserva esta por terminar`,
  extendTime: `Extender tiempo`,
  getOutTime: `Salir`,
  singOff: `Cerrar sesión`,
  myAccount: `Mi cuenta`,
  congratulations: `Felicitaciones`,
  reservationComplete: `tu reserva ha sido realizada y confirmada con éxito`,
  reservationCompleteRequest: `tu solicitud de reserva ha sido realizada con éxito`,
  emailInformation: `En unos minutos recibirás un correo con los datos de esta`,
  rememberTable: `Recuerda que la mesa, únicamente se guardará por 20 minutos; después de este tiempo podrás hacer uso de tu reserva, únicamente si tenemos disponibilidad`,
  waitYou: `¡Te esperamos!`,
  seeBooking: `Ver reserva`,
  myBooking: `Mis reservas`,
  updateField: `Actualizar datos`,
  myProfile: `Tu perfil`,
  pleaseProfileInformation: `Por favor llena los datos de tu perfil`,
  fullName: `Nombre completo`,
  saveProfile: `Guardar perfil`,
  adults: `Adultos`,
  kids: `Niños`,
  kids2Years: `Menores de 2 años`,
  kids3Years: `Menores de 3 años`,
  contact11And9: `Comunícate con nosotros en el horario de 11am a 9pm`,
  peopleRecommend10: `Para  grupos de  10 personas en adelante recomendamos diseñar un menú`,
  contributionArtist: `El aporte al artista (cover) es el valor que se cancela en el momento de la reserva y no es consumible.`,
  contributionArtist1: `El`,
  contributionArtist2: `aporte al artista (cover)`,
  contributionArtist3: `es el valor que se cancela en el momento de la reserva y`,
  contributionArtist4: `no es consumible`,
  advanceReservation1: `Para garantizar tu reserva se realiza un anticipo de`,
  advanceReservation2: `por Adulto o Niños mayores de 10 años y de`,
  advanceReservation3: `para Niños entre 4 y 9 años. Niños menores a 3 años no pagan`,
  breakfastShow: `Incluye Desayuno y Show`,
  eventChargePerson1: `Este evento tiene un cobro de`,
  eventChargePerson1Chato: `Este evento tiene un cobro anticipado de`,
  eventChargePerson2: `por persona`,
  contactNeedNumberPerson: `Comunícate con nosotros si necesitas un evento o si requieres una reserva que sobrepase el límite de personas`,
  eventDay: `Este día hay un evento`,
  selectAvailableHour: `Selecciona una hora disponible`,
  selectWhereBook: `Selecciona en donde quieres reservar`,
  noSchedulesAvailable: `No existen horarios disponibles`,
  connectionError: `Se ha excedido el tiempo de búsqueda, por favor revisa  tu conexión a internet y vuelve a intentarlo`,
  loadingHours: `Buscando horas disponibles `,
  normalBooking: `Reservas Normales`,
  noFindAvailable: `¿No encuentras disponibilidad?`,
  seeAvailable: `Ver disponibilidad`,
  inHere: `aqui`,
  lookingAvailable: `Buscando disponibilidad en`,
  findAvailableOther: `Encontramos disponibilidad en otros restaurantes`,
  goToRestaurant: `Ir al restaurante`,
  foundReservation: `Hemos encontrado una reserva para esta fecha`,
  continueReservation: `¿Estás seguro de continuar con la reserva actual?`,
  rumbaInfo: `Información Rumba`,
  rumbaNoTable: `Las reservas para la rumba no incluyen una mesa, tan solo le darán acceso al restaurante show y no se aceptarán menores de edad`,
  completeData: `COMPLETA LOS DATOS`,
  next: `SIGUIENTE`,
  timeNoAvailable: `Disculpe, pero este horario no esta disponible`,
  locationCocktail: `UBICACIÓN EN MESA COCTELERA`,
  locationTable: `UBICACIÓN EN MESA`,
  locationMuch: `UBICACIÓN DE PIE / BARRA / SALAS`,
  sendGiftSpecial: `Envíale una tarjeta de regalo a alguien especial`,
  sendGiftSpecialBiferia: `Envía un bono de La Biferia`,
  givePresent: `Regalar`,
  login: `Iniciar Sesión`,
  passRecovery: `Recuperación de contraseña`,
  emailAndSubmit: `Ingrese la dirección de correo electrónico y haga clic en Enviar`,
  passReset: `Le enviaremos instrucciones por correo electrónico sobre cómo restablecer su contraseña.`,
  send: `ENVIAR`,
  sending: `Enviando`,
  tokenResetPass: `Se ha enviado a tu correo un token para restablecer la contraseña, por favor copialo y pegalo a continuación`,
  check: `VERIFICAR`,
  checking: `Verificando`,
  logInHere: `Si ya tienes una cuenta inicia sesión aquí`,
  incorrectCredentials: `Credenciales incorrectas`,
  forgotPass: `¿Olvido su contraseña?`,
  getIn: `Entrar`,
  registerHere: `Si no tienes cuenta, regístrate aquí`,
  selectOption: `Seleccionar una opción`,
  strongOption: `OPCIONES PLATO FUERTE`,
  hamburge3Cordi: `Hamburguesa 3 Cordilleras con papas chips`,
  sanduche3Cordi: `Sanduche Pulled Pork con papas chips`,
  noAvailableTime: `No existen horarios disponibles`,
  noAvailableZone: `No hay zonas con disponibilidad`,
  payReservation: `Confirmar y pagar`,
  typeReservation: `Tipo de Reserva`,
  cancelReservation: `Cancelar Reserva`,
  areSure: `¿Estás Seguro?`,
  emailDetails: `Recibirás un correo con todos los detalles, recuerda que una vez cancelada no podrás volver a tu reserva`,
  termsAndCondi: `Aceptar términos y condiciones de cancelación aquí.`,
  confirm: `Confirmar`,
  selectDataReservation: `Seleccionar`,
  modifyReservaton: `Modifica tu reserva aquí`,
  haveBonus: `Tienes un bono de`,
  cancel: `Cancelar`,
  noAssotaciatedR: `No tiene reservas asociadas`,
  user: `Señor Usuario`,
  hi: `Hola`,
  reservationOf: `Que deseas hacer con tu reserva del`,
  editBooking: `Editar Reserva`,
  cancelBooking: `Cancelar Reserva`,
  diners2: `Acompañantes`,
  choosePayment: `Elige un medio de pago y luego haz click en pagar para completar tu reserva`,
  termsReservation: `Al dar clic en "Confirmar reserva" declara que conoce y acepta el tratamiento de datos personales previamente informado y al enviar este formato a Precompro acepta nuestros Términos y condiciones y la Política de Privacidad*. Para cumplir con el objeto social de Precompro, al hacer la reserva se compartirá su información con el restaurante aliado.`,
  termsReservation1: `Al dar clic en "Confirmar reserva" declara que conoce y acepta el tratamiento de datos personales previamente informado y al enviar este formato a Precompro acepta nuestros`,
  termsReservation2: `y`,
  termsReservation3: `Para cumplir con el objeto social de Precompro, al hacer la reserva se compartirá su información con el restaurante aliado.`,
  termsReservationPay: `Al dar clic en "Pagar" declara que conoce y acepta el tratamiento de datos personales previamente informado y al enviar este formato a Precompro acepta nuestros Términos y condiciones y la Política de Privacidad*. Para cumplir con el objeto social de Precompro, al hacer la reserva se compartirá su información con el restaurante aliado.`,
  termsReservationPay1: `Al darle  clic en "Pagar" y continuar declara que conoce y acepta el tratamiento de datos personales previamente informado y está aceptando los `,
  termsReservationPay2: `y la`,
  termsReservationPay2Aux: `de Precompro y del restaurante`,
  termsReservationPay3: `Para cumplir con el objeto social de Precompro, al hacer la reserva se compartirá su información con el restaurante aliado.`,
  termsPaymentWompi: `Acepto haber leído y entendido los`,
  termsPaymentWompi1: `términos y condiciones y la política de privacidad de Wompi`,
  termsPaymentWompi2: `para hacer esta compra.`,
  addCard: `AGREGAR TARJETA`,
  registerPhoneReservation: `Registra el número celular de la persona que reserva`,
  preCompraSucces: `tu Precompra ha sido realizada con éxito`,
  finish: `Finalizar`,
  confirmReservation: `Para completar tu reserva te quedan`,
  confirmReservationRequest: `Para completar tu solicitud de reserva te quedan`,
  informationReservation: `Recibirás notificaciones vía email`,
  informationReservationPayment: `Información de tu precompra`,
  informationReservationFree: `Información de tu reserva`,
  informationReservationFreeRequest: `Información de tu solicitud de reserva`,
  informationReservationContact: `Si tienes alguna consulta al respecto, por favor comunicarse al`,
  sms: `SMS`,
  whatsapp: `WhatsApp`,
  other: 'Otro',
  address: `Dirección`,
  order: `Pedido`,
  notificationReservationQ: `¿Por dónde deseas recibir notificaciones de tu reserva?`,
  notificationReservationQResponsive: `¿Por dónde deseas recibir notificaciones?`,
  notificationReservationRememberEmail: `*Recuerda revisar Bandeja de entrada y SPAM`,
  notificationReservationRememberSMS: `*SMS solo aplica para números colombianos`,
  legalPersonLabel: `¿El cliente es persona jurídica?`,
  companyName: `Escribir el nombre de la empresa`,
  notificationReservation: `Recibirás notificaciones de tu reserva vía email.`,
  updateBirthday: `Fecha de cumpleaños`,
  dayMonthYear: `DÍA/MES/AÑO`,
  day: `Día`,
  month: `Mes`,
  bioSegurity: `Por medidas de bioseguridad necesitamos el nombre de tus acompañantes`,
  addCompanions: `Agrega a tus acompañantes`,
  addCompa: `Agregar acompañante`,
  addCompas: `Agregar acompañantes`,
  suscribTo: `Suscribirse a`,
  acceptInfoEvents: `Acepto el envío de información relacionado a (eventos, descuentos, promociones)`,
  chooseMainPlate: `Elige los platos fuertes para tus invitados.`,
  chooseExperience: `Selecciona una experiencia`,
  rememberChoosePlate1: `Recuerda que solo puedes elegir`,
  rememberChoosePlate2: `platos fuertes`,
  selectedMainPlate: `Platos fuertes seleccionados`,
  person: `Persona`,
  typeCustomer: `¿Qué tipo de cliente eres?`,
  howFind: `¿Como nos conociste?`,
  experience: `Experiencia`,
  discountCode: `Agregar código de descuento`,
  enterCodeGift: `Ingresa el código de tu cupón o giftcard`,
  giftCard: 'Bono regalo',
  eventsTabOpen: 'Ver eventos',
  eventsTabClose: 'Cerrar eventos',
  eventsCalendar: 'Calendario de eventos',
  selectOneOurEvents: 'Selecciona uno de nuestros eventos',
  selectOneOurEvents_1: 'Selecciona el evento',
  selectOneOurEvents_2: 'Próximos eventos',
  lastQuotas: 'ÚLTIMOS CUPOS',
  perPeople: 'por persona',
  moreInfo: 'Más info',
  noHoursEvent: 'No hay horarios disponibles',
  scheduleEvent: 'Horario:',
  eventValue: 'Valor evento:',
  advanceValue: 'Valor anticipo:',
  bookEvent: 'Reservar',
  bookInThisEvent: 'Reservar en este evento',
  closeEvent: 'Cerrar',
  availabilityEventText: 'No hay eventos disponibles ',
  availabilityEventTextAux: 'en',
  menu: 'Ver Menú',
  addCode: `Agregar código`,
  celebrateSomething: `¿Celebras algo?`,
  includes: `INCLUYE`,
  beerMainD: `5 CERVEZAS + 1 PLATO FUERTE`,
  beerMainMusic: `5 CERVEZAS + 1 PLATO FUERTE + MÚSICA EN VIVO`,
  mainOption: `OPCIONES PLATO FUERTE`,
  potato3Cordi: `Alitas a la 3 Cordilleras con papas rústicas`,
  burgePotate3Cordi: `Hamburguesa 3 Cordilleras con papas rústicas`,
  veggieBurger: `Hamburguesa Vegetariana (portobello asado) con papas rústicas`,
  porkSandwich: `Sanduche de cerdo desmechado con papas rústicas`,
  decoration: `Decoración`,
  personConsumable: `Persona Consumibles`,
  total: `Total`,
  details: `Detalles`,
  bottle4People: `Una botella por cada 4 personas`,
  costMenu: `valor menú 750 MIL`,
  costMenu2: `valor menú 590 MIL`,
  costMenu3: `valor menú 400 MIL`,
  paidOut: `Pagado`,
  cover: `Cover`,
  mixer: `Mezcladores`,
  services: `Servicios`,
  pendingPayment: `Por pagar`,
  save: `Guardar`,
  chooseDecoration: `Selecciona la decoración que desees`,
  chooseDecorationMultiple: `Selecciona las decoraciones que desees`,
  chooseMainD: `Escoge el plato fuerte que desees`,
  mainDishe: `Nuestros`,
  mainDishe2: `platos fuertes son`,
  chooseOptionPerson: `Elige la opción por persona`,
  selectedDishes: `Fueron seleccionados los`,
  selectedDishes2: `platos con éxito.`,
  returnSelectPayment: `Volver a medio de pago`,
  creditCard: `Tarjeta de crédito`,
  payerDetails: `Datos del pagador`,
  cardholder: `Nombre completo del titular de la tarjeta`,
  idType: `Tipo Identificación`,
  cardNumber: `Número de la tarjeta`,
  securityCode: `Código de seguridad`,
  year: `Año`,
  nCuota: `Nro Cuota`,
  saveCard: `Deseas guardar esta tarjeta para compras futuras`,
  termsAndCondiW: `Términos y condiciones`,
  privacyPolicyW: `Política de Privacidad`,
  securePayments: `Pagos seguros a través de`,
  addCardPay: `Pagar`,
  pay: `Pagar`,
  payment: `Pago`,
  city: `Ciudad`,
  select2: `Seleccione`,
  cuota: `Cuota`,
  debitCard: `Tarjeta débito`,
  chooseBank: `Escoge la entidad bancaria`,
  information: `Información`,
  naturalPerson: `Persona Natural`,
  legalPerson: `Persona Jurídica`,
  bank: `Banco`,
  confirmPass: `Confirmar Contraseña`,
  orSing: `inicia sesión con`,
  continueGuest: `Continuar como invitado`,
  rememberCreatingAccount: `Recuerda que al crear una cuenta no deberás volver a ingresar tus datos`,
  return2: `Regresar`,
  modify: `Modificar`,
  locateBar: `Si tu reserva es de dos (2) personas, quedarás ubicado en la barra`,
  corporateEvent: `Si necesitas una cena empresarial o un evento corporativo`,
  personConsumable1: `Para garantizar tu reserva se realiza un anticipo de`,
  personConsumable2: `por persona 100% consumibles`,
  guaranteeReser1: `Para garantizar tu reserva se realiza un anticipo de`,
  guaranteeReser2: `por Adulto y`,
  guaranteeReser3: `por Niño`,
  keepMind: `Ten en Cuenta`,
  noAvailableNumnberPerson: `Lo sentimos no hay disponibilidad para esta cantidad de personas`,
  selectedNumberPerson: `Selecciona número de personas`,
  selectDate: `Selecciona Fecha`,
  selectHour: `Selecciona Hora`,
  add: `Agregar`,
  cardData: `Datos de la tarjeta crédito`,
  password: `Contraseña`,
  signUp: `Regístrate`,
  register: `Registrar`,
  waitingList: `Lista de Espera`,
  waitingListText1: ` no tenemos disponibilidad. Puedes dejarnos tus datos y serás ingresado a la lista de espera.`,
  waitingListText2: ` Sin embargo, esto `,
  waitingListText3: `NO`,
  waitingListText4: ` significa que tengas una mesa en el restaurante.`,
  waitingListText5: `En caso de que se libere una mesa, nos contactaremos contigo.`,
  waitingListTextDate: `Para la fecha `,
  waitingListTextHour: ` a la hora `,
  waitingListOptionRestaurant: `Ver restaurantes aliados`,
  waitingListOption: `Ingresar a lista de espera`,
  modalExperiences: {
    text1: `Selecciona una experiencia para cada persona`,
    text2: `Ver descripción`,
    text3: `por persona`,
    text4: `Para seleccionar una experiencia debes hacer click en`,
    text5: `“Añadir experiencia”`,
    text6: `para cada persona`
  },
  stepper: {
    pay: `Confirmar y pagar`
  },
  modalWaitingList: {
    continue: `Continuar`,
    textPhone: `Indicativo + tel`,
    send: 'Enviar'
  },
  modalSuccessWaitingList: {
    successWaitingList: `Han sido ingresados a la lista de espera los datos.`,
    people: `Personas`,
    thanks: `Gracias por contar con nosotros`
  },
  modalInformationAction: {
    text1: `¡En este momento no tenemos disponibilidad!`,
    text2: `¡La hora que estas seleccionando no está disponible!`,
    text3: `Selecciona una hora que este disponible`,
    text4: `¿Quieres registrarte en nuestra lista de espera?`,
    text5: `Puedes ver la disponibilidad en otros restaurantes`,
    text6: `¿Que te gustaría hacer?`,
    text7: `Cerrar`,
    text8: `Regístrate en la lista de espera`,
    text9: `Ver disponibilidad en otros restaurantes`
  },
  giftCardView: {
    title: `Personaliza tu bono de regalo`,
    value: `Valor de la tarjeta`,
    form: {
      shippingDate: `Fecha de envío`,
      shippingDate2: `Fecha de envío de la tarjeta`,
      to: `Para: quién recibe el regalo`,
      emailTo: `Correo: a donde lo enviaremos`,
      from: `De: Tu nombre`,
      emailFrom: `Tu correo`,
      message: `Escribe un mensaje para la tarjeta`,
      action: `Pagar`
    },
    payment: {
      method: `Medio de pago`,
      text1: `Elige un medio de pago y luego haz clic en`,
      text2: `pagar para completar tu reserva.`,
      text3: `Tarjeta de crédito`,
      text4: `Tarjeta débito`,
      text5: `PSE inactivo, inténtelo más tarde`,
      text6: `Atrás`,
      text7: `*Al hacer click en Pagar estás aceptando`,
      text8: `los términos y condiciones`,
      text9: `la política de privacidad`,
      text10: `de Precompro; al igual que las políticas de cancelación y/o modificación del restaurante.`,
      text11: `Volver a medios de pago`,
      text12: `TARJETA DE CRÉDITO`,
      text13: `PAGA CON TU`,
      text14: `TARJETA DE CRÉDITO`,
      text15: `Diligencia el formulario`,
      text16: `y luego dale clic en pagar.`,
      text17: `Datos del comprador`,
      text18: `Nombre como aparece en la tarjeta`,
      text19: `Número de documento`,
      text20: `Celular`,
      text21: `Dirección`,
      text22: `El valor del campo es requerido`,
      text23: `Ciudad`,
      text24: `Datos de la tarjeta`,
      text25: `Número de la tarjeta`,
      text26: `Mes`,
      text27: `Año`,
      text28: `No. Cuotas`,
      text29: `*Al hacer click en Pagar estás aceptando`,
      text30: `los términos y condiciones`,
      text31: `y`,
      text32: `la política de privacidad`,
      text33: `de Precompro; al igual que las políticas de cancelación y/o modificación del restaurante.`,
      text34: `NOMBRE DEL PROPIETARIO`,
      text35: `Correo`,
      text36: `Tipo de documento`,
      text37: `PAGA CON TU TARJETA DÉBITO`,
      text38: `Selecciona tu banco`,
      text39: `Nombre del titular`,
      text40: `Persona Natural`,
      text41: `Pagar`,
      text42: `Pagos seguros a través de`,
      text43: `Persona Jurídica`,
      text44: `Políticas de cancelación y/o modificación`,
      text45: `El`,
      text46: `USUARIO`,
      text47: `podrá hacer uso de su bono de regalo ...`,
      text48: `Aceptar`
    },
    confirmation: {
      text1: `¡Felicitaciones!`,
      text2: `Estado de la transacción`,
      text3: `Generada y será enviada a`,
      text4: `A la dirección de correo`,
      text5: `En la fecha escogida`,
      text6: `Aceptar`,
      text7: `Política Tratamiento de Datos Personales de Click2buy S.A.S.`,
      text8: `Términos y condiciones.`,
      text9: `Aceptar`
    }
  },
  checkoutView: {
    text1: `Política Tratamiento de Datos Personales de Click2buy S.A.S.`,
    text2: `Términos y condiciones`,
    components: {
      text1: `por persona`,
      text2: `Precio incluye impuestos.`,
      text3: `Propina sugerida:`,
      text4: `En la Casa del Chef.`,
      text5: `Cine y Cocina.`,
      text6: `Clases de Cocina`,
      text7: `Tiendita a Domicilio.`,
      text8: `Preguntas`,
      text9: `* (Opcional)`,
      text10: `¿Ha estado en contacto con personas con infección respiratoria aguda o personas diagnosticadas con COVID-19?`,
      text11: `Si`,
      text12: `No`,
      text13: `¿Presenta alguno de los siguientes síntomas: dolor de garganta, fiebre mayor a 38 grados, tos seca y persistente, dificultad para respirar, dolor muscular de cabeza y/o de articulaciones?`,
      text14: `Al agregar los datos de sus acompañantes declara que tiene la autorización previa de ellos y están aceptando el tratamiento de datos`,
      text15: `Política Tratamiento de Datos Personales de Click2buy S.A.S.`,
      text16: `Términos y condiciones`,
      text17: `Información`,
      text18: `Escoge Entidad`,
      text19: `Políticas de cancelación y/o modificación`,
      text20: `El`,
      text21: `USUARIO`,
      text22: `podrá hacer cancelación o modificación de la reserva, siempre y cuando sea con`,
      text23: `hora(s) de anticipación a la hora seleccionada con la compra del Ticket. Una vez pasado este tiempo, la reserva se considera “confirmada” y no habrá derecho a devolución o modificaciones por parte del restaurante.`,
      text24: `Aceptar`,
      text25: `Transacción Rechazada:`,
      text26: `Motivo`,
      text27: `Por favor verifica otra forma de pago`,
      text28: `Aún puedes realizar el pago siempre y cuando aún te quede tiempo y así confirmar tu reserva.`,
      text29: `¡Te esperamos!`,
      text30: `Volverlo a intentar con otro medio`,
      text31: `Transacción en Proceso:`,
      text32: `Estamos validando el pago`,
      text33: `La aprobación de la transacción puede tardar unos minutos, por esto tu reserva puede demorarse unos minutos en verse reflejada o si es rechazada vuelve a intentarlo con otro medio de pago.`,
      text34: `Términos y condiciones del pago`,
      text35: `Puedes cancelar o modificar tu reserva`,
      text36: `Hora reserva`,
      text37: `Para garantizar tu reserva se realiza un anticipo por persona 100% consumibles. En caso de no asistir o no cancelar con mínimo {hours} hora{plur} de anticipación, este no será devuelto. Para ver todos los`,
      text38: `Términos y Condiciones de pago haz clic `,
      text39: `aquí `
    }
  },
  errorView: {
    text1: `Oops! Página no encontrada.`,
    text2: `No hemos podido encontrar la página que estabas buscando.`
  },
  eventsView: {
    text1: `EVENTOS`,
    text2: `Experiencias`,
    text3: `Información de eventos`,
    text4: `No se encontraron eventos para las próximas fechas.`
  },
  maintenanceView: {
    text1: `¡Disculpe!`,
    text2: `No se encuentra habilitado el establecimiento.`
  },
  pollView: {
    text1: `Enviar`,
    text2: `Enviando...`,
    text3: `Si tienes alguna consulta al respecto, por favor comunicarse al`,
    text4: `ó escríbenos a`,
    text5: `¡Gracias!`,
    text6: `por tu respuesta y ayudarnos a mejorar`,
    text7: `Comparte tu calificación a través de:`
  },
  pollOldView: {
    text1: `Se han enviado con éxito sus respuesta, Gracias por calificarnos!`,
    text2: `Te invitamos a calificarnos en TripAdvisor`,
    text3: `* Por favor contesta esta pregunta`,
    text4: `(Opcional)`,
    text5: `i tienes alguna consulta al respecto, por f`,
    text6: `Enviar Calificación`,
    text7: `Enviando Calificación...`,
    text8: `Si tienes alguna consulta al respecto, por favor comunicarse al`,
    text9: `ó escríbenos a`
  },
  pollQrView: {
    text1: `*Conozco y acepto la política de tratamiento de mis datos personales`,
    text2: `*Debes de aceptar la política de tratamiento de datos`,
    text3: `¿Llegaste por una reserva?`,
    text4: `Si`,
    text5: `No`,
    text6: `Enviar`,
    text7: `Enviando...`,
    text8: `Si tienes alguna consulta al respecto, por favor comunicarse al`,
    text9: `ó escríbenos a`,
    text10: `¡Gracias!`,
    text11: `por tu respuesta y ayudarnos a mejorar`,
    name: 'Nombre',
    identification: 'Documento de identidad',
    cellphone: 'Teléfono',
    email: 'Correo electrónico',
    validations: {
      phoneRequired: 'El campo de teléfono es requerido'
    }
  },
  prepagoView: {
    text1: `Datos de la precompra`,
    text2: `Para pagar tu reserva te quedan`,
    text3: `No. de personas`,
    text4: `Fecha`,
    text5: `Hora`,
    text6: `Valor por persona`,
    text7: `Total personas:`,
    text8: `Valor total`,
    text9: `*Para garantizar tu reserva se realiza un anticipo por persona 100% consumibles.`,
    text10: `Datos de cliente`,
    text11: `Acepto`,
    text12: `términos y condiciones`,
    text13: `Autorizo el`,
    text14: `tratamiento de mis datos personales`,
    text15: `con las siguientes condiciones.`,
    text16: `Selecciona un medio de Pago`,
    text17: `Tarjeta de crédito`,
    text18: `Tarjeta débito`,
    text19: `Nombre como aparece en la tarjeta`,
    text20: `Tipo de documento`,
    text21: `Número de documento`,
    text22: `Correo`,
    text23: `Numero de teléfono`,
    text24: `Datos de la tarjeta`,
    text25: `Numero de la tarjeta`,
    text26: `Mes`,
    text27: `Año`,
    text28: `No.Cuotas`,
    text29: `Pagos seguros a través de`,
    text30: `Pagar`,
    text31: `Selecciona tu banco`,
    text32: `Nombre`,
    text33: `Persona Natural`,
    text34: `Persona Jurídica`,
    text35: `*Al hacer click en Pagar estás aceptando los términos y condiciones y la política de privacidad de Precompro; al igual que
  las políticas de cancelación y/o modificación del restaurante.`,
    text36: `Pagos seguros a través de`,
    text37: `Pagar`,
    text38: `Estos datos no son modificables`
  },
  reservationView: {
    text1: `Que deseas hacer con tu precompra del`,
    text2: `La reserva que tenias para el`,
    text3: `ha quedado cancelada con éxito.`,
    text4: `Reconfirmar`,
    text5: `Volver a reservar`,
    text6: `Si tienes alguna consulta al respecto, por favor comunicarse al`,
    text7: `Esta reserva ya`,
    text8: `se encuentra`,
    text9: `Esta reserva ya no está dentro del tiempo para modificar o cancelar.`
  },
  selectView: {
    login: {
      text1: `Recuperación de contraseña`,
      text2: `Ingrese la dirección de correo electrónico y haga clic en`,
      text3: `Enviar.`,
      text4: `Ingresa el código que recibiste en tu correo`,
      text5: `Si ya tiene una cuenta con nosotros recibirá instrucciones por correo electrónico sobre cómo restablecer su contraseña.`,
      text6: `Comprobar`,
      text7: `Cambiar Clave`,
      text8: `Nueva Contraseña`,
      text9: `Repite tu nueva contraseña`,
      text10: `Volver`,
      text11: `Nombre completo`,
      text12: `Contraseña`,
      text13: `¿Olvido su contraseña?`,
      text14: `Iniciar sesión `,
      text15: `Si ya tienes cuenta puedes`,
      text16: `Si aún no tienes te puedes`,
      text17: `Registrar`,
      text18: `Registrarse`,
      text19: `*Recuerda que al crear una cuenta no deberás volver a ingresar tus datos`,
      text20: `También puedes iniciar sesión con`,
      text21: `Regístrate ahora.`,
      text22: `Al ingresar los datos y continuar declara que conoce y acepta el tratamiento de datos personales previamente informado y está aceptando`,
      text23: `y`,
      text24: `de Precompro y del restaurante.`,
      text25: `Política Tratamiento de Datos Personales de Click2buy S.A.S.`,
      text26: `Términos y condiciones`,
      text27: `Documento de identidad`,
      text28: `Confirmar contraseña`

    },
    information: {
      text1: `¡Buscando disponibilidad en otros restaurantes!`
    },
    schedule: {
      text1: `Hora`
    }
  },
  customizeReservation: `Personaliza tu reserva`,
  babys: `Bebés`,
  addDecoration: `Agregar decoración`,
  addExperience: `Agregar experiencia`,
  celebrationQuestion: `¿Qué celebras?`,
  placeholderDate: `Día/Mes/Año`,
  addMainPlates: `Agregar plato fuerte`,
  paymentSummary: `Resumen de pago`,
  prePurchase: `Precompra`,
  event: `Evento`,
  documentNumber: `Número de documento`,
  lunes: `Lunes`,
  martes: `Martes`,
  miércoles: `Miércoles`,
  jueves: `Jueves`,
  viernes: `Viernes`,
  sábado: `Sábado`,
  domingo: `Domingo`,
  preCompra: `Precompra`,
  newEditReservation: {
    today: `Hoy`,
    tomorrow: `Mañana`,
    currentReservation: `Esta es tu reserva actual`,
    modifiedReservation: `Esta es tu reserva modificada`,
    currentDateReservation: `Fecha reserva actual`,
    changeZoneQuestion: `¿Quieres cambiar tu zona actual?`,
    currentZoneSelected: `Esta es tu zona actual`,
    availableZones: `Estas son las zonas con disponibilidad`,
    yourModifiedZone: `Esta es tu zona modificada`,
    selectNewZone: `Selecciona tu nueva zona`,
    yourNewZone: `Tu nueva zona es:`,
    newDateReservation: `Fecha nueva`,
    seeMoreDates: `Ver más fechas`,
    modifiedSuccess: `Tu reserva fue modificada con éxito`,
    updatedData: `Estos son los datos de tu reserva con la nueva modificación`,
    currentPeople: `Número de personas actuales`,
    aditionalPeople: `Número de personas adicionales`,
    valueToPay: `Valor a pagar`,
    paidValue: `Valor cancelado fue de`,
    aditionalValue: `Valor adicional a pagar`,
    previous: `Anterior`,
    next: 'Siguiente',
    aditionalsReservation: `Adicional en tu reserva tienes`,
    modifyAditionals: `Si deseas modificar las experiencias o la decoración click en el botón`,
    hourCurrent: 'Hora reserva actual',
    hourNew: 'Hora nueva'
  },
  morePeopleMessageDefault: `Si deseas realizar una reserva para un número mayor de personas, debes comunicarte al teléfono:`,
  morePeopleMessage: `Para esta hora el máximo de personas disponible por reserva es de <strong>({peopleMaxTranslate})</strong>. Selecciona otra hora o cambia el número de personas.`,
  morePeopleMessageDate: `Para este día el máximo de personas disponibles es de <strong>({peopleMaxTranslate})</strong>. Selecciona otro día o cambia el número de personas.`,
  checkoutValidations: {
    phone: {
      required: 'El teléfono es requerido',
      numeric: 'Ingrese solo números',
      min: 'Debe ingresar un mínimo de caracteres',
      max: 'Ha superado el límite de caracteres'
    },
    documentNumber: {
      required: 'El número de documento es requerido',
      number: 'Solo se permiten números'
    },
    bank: {
      required: 'El banco es requerido'
    },
    cardNumber: {
      required: 'El número de tarjeta es requerido',
      max: 'El número de tarjeta debe ser entre 8 y 19'
    },
    dateCard: {
      required: 'Campo requerido',
      invalid: 'Campo invalido'
    },
    field: {
      required: 'Campo requerido'
    }
  },
  branch: {
    title: 'Escoge tu Sede',
    description: 'Tenemos más de una sede. Para continuar, debes<br> seleccionar en cual quieres realizar tu reserva.'
  }
};
