import App from './App.vue';
import mixinWhiteLabel from './mixins';
import router from './router';
import store from './store';

import validatorEn from '@/helpers/validator/en';
import validatorEs from '@/helpers/validator/es';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import Buefy from 'buefy';
import Dropdown from 'hsy-vue-dropdown';
import moment from 'moment-timezone';
import LocalStorage from 'store';
import VCalendar from 'v-calendar';
import vClickOutside from 'v-click-outside';
import VueMask from 'v-mask';
import VeeValidate, { Validator } from 'vee-validate';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import * as VueLoaders from 'vue-loaders';
import VueMobileDetection from 'vue-mobile-detection';
import VueMoment from 'vue-moment';
import Notifications from 'vue-notification';
import Vue2Filters from 'vue2-filters';

import { getYearsPayment } from '@/helpers/Plugins/getYearsPayment';
import analyticClient from './analytics/analyticClient';
import i18n from './helpers/i18n';

import VueSocketIO from 'vue-socket.io-extended';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

// import { report as discordNotify } from './helpers/apis/discord';
import 'moment/locale/es';

import 'buefy/dist/buefy.css';
import 'vue-loaders/dist/vue-loaders.css';

Vue.config.productionTip = false;

// Axios
const Axios = axios.create({
  baseURL: process.env.VUE_APP_URL,
  withCredentials: false
});
axiosRetry(Axios, { retries: 1 });
Axios.interceptors.request.use((request) => {
  if (LocalStorage.get('_token')) {
    request.headers['Authorization'] = 'Bearer ' + LocalStorage.get('_token');
  }
  if (request.data && !request.headers['Content-Type']) {
    request.headers['Accept'] = 'application/json';
    request.headers['Content-Type'] = 'application/json';
    request.data = JSON.stringify(request.data);
  }
  return request;
});
Axios.interceptors.response.use(undefined, err => {
  if (err.toJSON().message === 'Network Error') {
    return Promise.reject(err);
  }
  let res = err.response;
  if (res) {
    if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
      LocalStorage.clearAll();
      return Promise.reject(err);
    }
    if (res.status === 403) {
      return Promise.reject(err);
    }
  }
});
analyticClient(Axios);
Vue.use(Buefy);
Vue.use(VueMask);
Vue.use(Dropdown);
Vue.use(VueLoaders);
Vue.use(Vue2Filters);
Vue.use(VueMobileDetection);
Vue.use(getYearsPayment);
Vue.use(VeeValidate, {
  locale: LocalStorage.get('lang') || 'es',
  dictionary: {
    es: validatorEs,
    en: validatorEn
  }
});
Vue.use(vClickOutside);
Vue.use(Notifications);
Vue.use(VCalendar, {
  locales: {
    'es': {
      firstDayOfWeek: 2,
      masks: {
        weekdays: 'W'
      }
    }
  }
});
Validator.localize('es', validatorEs);
Vue.use(VueMoment, {
  moment
});
Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
    value = value.substring(0, limit - 3) + '...';
  }
  return value;
});

Vue.mixin(mixinWhiteLabel);
Vue.use(VueAxios, Axios);
LocalStorage.set('keySelectWs', uuidv4());
const vendorId = LocalStorage.get('_id') ? LocalStorage.get('_id') : LocalStorage.get('keySelectWs');
Vue.use(VueSocketIO, io(process.env.VUE_APP_WS, {
  autoConnect: true,
  reconnection: true,
  forceNew: true,
  reconnectionAttempts: Infinity,
  transports: ['websocket'],
  upgrade: false,
  query: {
    vendorId
  }
}));
Vue.config.errorHandler = (err, vm, info) => {
  console.error(err);
  console.error(info);
  // discordNotify({ title: `  💥\n\nAmbiente: ${process.env.NODE_ENV} \nMicroFrontend: Select`, description: `${info}\n\n${err.stack}`, color: '16723509' });
};
Vue.config.warnHandler = (err, vm, info) => {
  console.error(err);
  console.error(info);
  // discordNotify({ title: `  ⚠️\n\nAmbiente: ${process.env.NODE_ENV} \nMicroFrontend: Select`, description: err, color: '16776960' });
};
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
