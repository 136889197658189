<template>
  <div id="app">
    <b-loading :active.sync="loader" :can-cancel="false" :is-full-page="true"></b-loading>
    <ModalVerifyingTransaction v-if="verifyingPayment" :active="verifyingPayment" ></ModalVerifyingTransaction>
    <Language v-if="validateBtnLanguage()" />
    <router-view />
  </div>
</template>
<script>
import Language from './components/language/language.vue';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import ModalVerifyingTransaction from '@/components/ModalVerifyingTransaction.vue';
import serviceAuth from './helpers/apis/serviceAuth';
import authTypes from '@/store/types/auth';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';

export default {
  components: { Language, ModalVerifyingTransaction },
  mounted () {
    // Obtener los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const tokenAuth = urlParams.get('oAuthToken');
    const errorAuth = urlParams.get('oAuthError');
    if (tokenAuth) {
      // Almacenar el token en localStorage
      this.$store.commit(whiteLabelTypes.mutations.setDisabledOnLog, true);
      const preReservationInfo = window.localStorage.getItem('_reservation_time');
      if (preReservationInfo) {
        this.$store.commit(reservationTypes.mutations.setSendReservation, false);
        this.$store.commit(whiteLabelTypes.mutations.setLoginProcessReservation, true);
      }
      this.getInfoUser(tokenAuth);
    }
    if (errorAuth) {
      // Mostrar un mensaje de error si hubo un problema
      this.$buefy.dialog.alert({
        title: 'Error',
        message: 'Error al realizar la autenticación: <br>' + decodeURIComponent(errorAuth),
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
        onConfirm: () => {
          window.location.href = '/';
        }
      });
    }
    const deviceId = window.localStorage.getItem('deviceId');
    if (deviceId === undefined || deviceId == null) {
      window.localStorage.setItem('deviceId', uuidv4());
    }
    if (this.vendor !== undefined) {
      if (this.vendor.timezone) {
        moment.tz.setDefault(this.vendor.timezone);
        this.$store.dispatch({
          type: 'global:getServerTime',
          data: {
            timezone: this.vendor.timezone
          }
        });
      }
    }
    const script = document.createElement('script');
    script.src = 'https://sdk.mercadopago.com/js/v2';
    document.head.appendChild(script);
  },
  methods: {
    getInfoUser (token) {
      serviceAuth.post('login/oauth', { token }).then(({ data }) => {
        // Almacenar datos en localStorage
        this.storeAuthData(data);

        // Actualizar el estado de la tienda
        this.$store.commit(authTypes.mutations.setUser, data.user);
        this.$store.commit(authTypes.mutations.setUserId, data.userId);

        // Validar y enviar la reserva si es necesario
        this.$store.commit(whiteLabelTypes.mutations.setDisabledOnLog, false);
        this.validateSendReservation();
      }).catch((error) => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Error al realizar la autenticación: <br/> ' + error,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
          onConfirm: () => {
            window.location.href = '/';
          }
        });
      });
    },
    storeAuthData (data) {
      window.localStorage.setItem('_token', data.token);
      window.localStorage.setItem('_user', btoa(JSON.stringify(data.user)));
      window.localStorage.setItem('_userId', data.userId);
    }
  },
  watch: {
    vendor () {
      if (this.vendor.timezone) {
        moment.tz.setDefault(this.vendor.timezone);
        this.$store.dispatch({
          type: 'global:getServerTime',
          data: {
            timezone: this.vendor.timezone
          }
        });
      }
    }
  },
  computed: {
    verifyingPayment () {
      return this.$store.getters['global:verifyingPayment'];
    }
  }
};
</script>
<style lang="scss">
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@import "./assets/styles/fonts.css";
@import "./assets/styles/index.scss";
</style>
