<template>
    <div id="modalNoAvailability" class="modals-bosy-little">
      <div
        class="black-label"
      >
        <div
          class="button-circle"
        >
          <span class="span-text-info">i</span>
        </div>
      </div>
      <div class="Content-modal-text">
        <span
          class="span-text"
          >No hay disponibilidad</span
        >
        <p
          class="text-info"
        > Por favor intenta de nuevo</p>
      </div>
      <div class="btns-modal">
        <button
        @click="load"
          class="full-btn-modal btn-modal-fill button-modal"
        >
        <span class="textColorBtn">Aceptar</span>
        </button>
      </div>
    </div>
</template>
<script>
export default {
  methods: {
    load () {
      location.reload();
    }
  }
};
</script>
<style lang="scss">
#modalNoAvailability {
    min-width: 364px;
.not-outline {
  outline: 0px !important;
  box-shadow: none !important;
}
.modals-bosy-little .btns-modal {
  display: flex;
  justify-content: center;
  margin-top: 0px !important;
  outline: 0px !important;
  box-shadow: none !important;
}

.modals-bosy-little {
  padding: 0px 0px 20px !important;
  border-radius: 30px;
}

.modals-bosy-little .btns-modal .full-btn-modal {
  min-width: 30%;
  height: 40px;
  border-radius: 9px;
  font-family: 'Source Sans Pro';
}
.full-btn-modal {
    background: var(--btnBgReservationFooter);
    border: 1px solid var(--btnBgReservationFooter);
}
p {
  font-size: 16px;
  font-family: 'Source Sans Pro Light';
}

.black-label {
  background: var(--navColor);;
  width: inherit;
  max-width: 480px;
  height: 50px;
  max-height: 85px;
  border-radius: 25px 25px 0px 0px;
  top: 0;
}

.button-circle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 55px;
  min-width: 55px;
  height: 55px;
  min-height: 55px;
  margin-left: 30px;
  border-radius: 50px;
  top: 25px;
  background:rgb(255, 0, 0);
  box-shadow:  0px 2px 5px 1px rgba(155, 155, 155, .5);
}

.span-text {
  display: flex;
  justify-content: center;
  font-size: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: 'Source Sans Pro';
  font-weight: bold;
  text-align: center;
}

.span-text-info {
  font-family: coonfortaBold;
  display: flex;
  justify-content: center;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}
.textColorBtn {
    color: var(--btnColorReservationFooter)
}
}
</style>
